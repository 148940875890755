import Vue from "vue";
import VueRouter from "vue-router";

import { auth, db_9 } from "@/firebase/init";

import AppLayout from "@/layouts/App.vue";
import ProfileLayout from "@/layouts/Profile.vue";
import PublicLayout from "@/layouts/Public.vue";
import { collection, getDocs } from "firebase/firestore";
Vue.use(VueRouter);

const routes = [
  {
    path: "",
    name: "PublicLayout",
    component: PublicLayout,
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("../views/home.vue"),
      },
      {
        path: "/register",
        name: "register",
        component: () => import("../views/register.vue"),
      },
      {
        path: "/reset-password",
        name: "reset-password",
        component: () => import("../views/reset-password.vue"),
      },
      {
        path: "/verify-email",
        name: "verify-email",
        component: () => import("../views/verify-email.vue"),
      },
      {
        path: "/change-password",
        name: "change-password",
        component: () => import("../views/change-password.vue"),
      },
      {
        path: "/promo",
        name: "promo",
        component: () => import("../views/promo.vue"),
      },
    ],
  },
  {
    path: "",
    name: "AppLayout",
    component: AppLayout,
    children: [
      {
        path: "/merchant",
        name: "merchant",
        component: () => import("../views/merchant/index.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/shop/:id/coupon",
        name: "facility-coupon",
        component: () => import("../views/facility/coupon.vue"),
        meta: {
          requiresAuth: true,
          facilityAccess: true,
        },
      },
      {
        path: "/shop/:id/tool-kit",
        name: "tool-kit",
        component: () => import("../views/facility/tool_kit.vue"),
        meta: {
          requiresAuth: true,
          isOwner: true,
        },
      },
      {
        path: "/shop/:id",
        name: "facility-dashboard",
        component: () => import("../views/facility/dashboard.vue"),
        meta: {
          requiresAuth: true,
          facilityAccess: true,
        },
      },
      {
        path: "/shop/:id/dashboard",
        name: "facility-dashboard",
        component: () => import("../views/facility/dashboard.vue"),
        meta: {
          requiresAuth: true,
          facilityAccess: true,
        },
      },
      {
        path: "/shop/:id/analytics",
        name: "facility-analytics",
        component: () => import("../views/facility/analytics.vue"),
        meta: {
          requiresAuth: true,
          facilityAccess: true,
        },
      },
      {
        path: "/shop/:id/orders",
        name: "facility-orders",
        component: () => import("../views/facility/orders.vue"),
        meta: {
          requiresAuth: true,
          facilityAccess: true,
        },
      },
      {
        path: "/shop/:id/credit-orders",
        name: "facility-credit-orders",
        component: () => import("../views/facility/credit_orders.vue"),
        meta: {
          requiresAuth: true,
          facilityAccess: true,
        },
      },
      {
        path: "/shop/:id/reports",
        name: "facility-reports",
        component: () => import("../views/facility/reports.vue"),
        meta: {
          requiresAuth: true,
          facilityAccess: true,
        },
      },
      {
        path: "/shop/:id/inventory-reports",
        name: "inventory-reports",
        component: () => import("../views/facility/inventory_reports.vue"),
        meta: {
          requiresAuth: true,
          facilityAccess: true,
        },
      },
      {
        path: "/shop/:id/expense-management",
        name: "expense-management",
        component: () => import("../views/facility/expense_management.vue"),
        meta: {
          requiresAuth: true,
          facilityAccess: true,
        },
      },
      {
        path: "/shop/:id/referral-management",
        name: "referral-management",
        component: () => import("../views/facility/referral/referral_management.vue"),
        meta: {
          requiresAuth: true,
          facilityAccess: true,
        },
      },
      {
        path: "/shop/:id/referral/transaction",
        name: "referral-transactions",
        component: () => import("../views/facility/referral/referral_transaction.vue"),
        meta: {
          requiresAuth: true,
          facilityAccess: true,
        },
      },
      {
        path: "/shop/:id/settings",
        name: "facility-settings",
        component: () => import("../views/facility/settings.vue"),
        meta: {
          requiresAuth: true,
          facilityAccess: true,
        },
      },
      {
        path: "/shop/:id/catalogue",
        name: "facility-catalogue",
        component: () => import("../views/facility/catalogue.vue"),
        meta: {
          requiresAuth: true,
          facilityAccess: true,
        },
      },
      {
        path: "/shop/:id/catalogue/out-going",
        name: "catalogue-out-going",
        component: () =>
          import("../views/facility/catalogue/transfer_out.vue"),
        meta: {
          requiresAuth: true,
          facilityAccess: true,
        },
      },
      {
        path: "/shop/:id/catalogue/coming-in",
        name: "catalogue-coming-in",
        component: () =>
          import("../views/facility/catalogue/transfer_in.vue"),
        meta: {
          requiresAuth: true,
          facilityAccess: true,
        },
      },
      {
        path: "/shop/:id/warehouse-management",
        name: "warehouse-management",
        component: () => import("../views/facility/warehouse_management.vue"),
        meta: {
          requiresAuth: true,
          facilityAccess: true,
        },
      },
      {
        path: "/shop/:id/raw-materials-management",
        name: "raw-materials-management",
        component: () =>
          import("../views/facility/raw_materials_management.vue"),
        meta: {
          requiresAuth: true,
          facilityAccess: true,
        },
      },
      {
        path: "/shop/:id/raw-materials/out-going",
        name: "raw-materials-out-going",
        component: () =>
          import("../views/facility/raw-materials/transfer_out.vue"),
        meta: {
          requiresAuth: true,
          facilityAccess: true,
        },
      },
      {
        path: "/shop/:id/raw-materials/coming-in",
        name: "raw-materials-coming-in",
        component: () =>
          import("../views/facility/raw-materials/transfer_in.vue"),
        meta: {
          requiresAuth: true,
          facilityAccess: true,
        },
      },
      {
        path: "/shop/:id/raw-materials/inventory-reports",
        name: "raw-materials-inventory-reports",
        component: () => import("../views/facility/raw-materials/inventory_reports.vue"),
        meta: {
          requiresAuth: true,
          facilityAccess: true,
        },
      },
      {
        path: "/shop/:id/sources",
        name: "facility-sources",
        component: () => import("../views/facility/sources.vue"),
        meta: {
          requiresAuth: true,
          facilityAccess: true,
        },
      },
      {
        path: "/merchant/edit/subscribe",
        name: "merchant-edit-subscribe",
        component: () => import("../views/merchant/subscribe.vue"),
        meta: {
          requiresAuth: true,
          isOwner: true,
        },
      },
      {
        path: "/merchant/subscription-details", //billing-details
        name: "merchant-subscriptiondetails",
        component: () => import("../views/merchant/subscriptiondetails.vue"),
        meta: {
          requiresAuth: true,
          isOwner: true,
        },
      },
      {
        path: "/merchant/business-analytics", //billing-details
        name: "business-analytics",
        component: () => import("../views/merchant/business_analytics.vue"),
        meta: {
          requiresAuth: true,
          isOwner: true,
        },
      },
      {
        path: "/merchant/account-information", //billing-details
        name: "merchant-account-info",
        component: () => import("../views/merchant/account_information.vue"),
        meta: {
          requiresAuth: true,
          isOwner: true,
        },
      },
      {
        path: "/create-shop",
        name: "create-shop",
        component: () => import("../views/facility/new.vue"),
        meta: {
          requiresAuth: true,
          isOwner: true,
        },
      },
      {
        path: "/shop/:id/team",
        name: "facility-team",
        component: () => import("../views/facility/team.vue"),
        meta: {
          requiresAuth: true,
          isOwner: true,
        },
      },
      {
        path: "/shop/:id/edit",
        name: "facility-edit",
        component: () => import("../views/facility/edit.vue"),
        meta: {
          requiresAuth: true,
          isOwner: true,
        },
      },
      {
        path: "/shop/:id/vendors",
        name: "vendor_details",
        component: () => import("../views/facility/vendor_details.vue"),
        meta: {
          requiresAuth: true,
          //isOwner: true,
        },
      },
      {
        path: "/shop/:id/knowledge-base",
        name: "knowledge_base",
        component: () => import("../views/facility/knowledge_base.vue"),
        meta: {
          requiresAuth: true,
          //isOwner: true,
        },
      },
    ],
  },
  {
    path: "",
    name: "ProfileLayout",
    component: ProfileLayout,
    children: [
      {
        path: "/merchant/edit/",
        name: "merchant-edit",
        component: () => import("../views/merchant/edit.vue"),
        meta: {
          requiresAuth: true,
          isOwner: true,
        },
      },
    ],
  },
  { path: "*", component: () => import("../views/page-not-found.vue") },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let requiresAuth = to.matched.some((x) => x.meta.requiresAuth); // User must be loggedin
  let isOwner = to.matched.some((x) => x.meta.isOwner); // User must be owner of the business
  let facilityAccess = to.matched.some((x) => x.meta.facilityAccess); // User must be added to the facility

  if (requiresAuth) {
    if (!auth.currentUser) {
      next({ name: "home" });
      return;
    }
    auth.currentUser
      .getIdToken()
      .then((idToken) => {
        if (!idToken) {
          next({ name: "home" });
        }

        let user = auth.currentUser;
        if (user && window.localStorage.getItem("MWUser")) {
          let authUser = JSON.parse(
            window.atob(window.localStorage.getItem("MWUser"))
          );
          if (isOwner) {
            if (authUser.isOwner) {
              next();
            } else {
              next({ name: "merchant" });
            }
          } else if (facilityAccess) {
            if (authUser.isOwner) {
              // If the is owner he has the access to any facility
              next();
            } else {
              let facilityId = to.params.id;
              // Checking the loggedin user id is added to the facility team details
              const facilityRef = collection(
                db_9,
                "merchant_facilities",
                facilityId,
                "team"
              );
              getDocs(facilityRef)
                .then((teamMembers) => {
                  if (!teamMembers.empty) {
                    var user_id = user.uid;
                    var teamIds = [];
                    teamMembers.forEach((member) => {
                      teamIds.push(member.data().user_id);
                    });
                    if (teamIds.includes(user_id)) {
                      next();
                    } else {
                      next({ name: "merchant" });
                    }
                  } else {
                    next({ name: "merchant" });
                  }
                })
                .catch((er) => {
                  next({ name: "merchant" });
                });
            }
          } else {
            next();
          }
        } else {
          next({ name: "home" });
        }
      })
      .catch((error) => {
        next({ name: "home" });
      });
  } else {
    next();
  }
});

export default router;
