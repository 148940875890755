<template>
  <v-list id="sidebar" class="no-print">
    <div v-if="isOwner">
      <v-list-item class="create_facility">
        <router-link v-if="activeSubscription" :to="{ name: 'create-shop' }">
          <v-list-item-title
            ><v-icon>mdi-plus-box-outline</v-icon>Create Shop
          </v-list-item-title>
        </router-link>
        <router-link v-else :to="{ name: 'merchant-edit-subscribe' }">
          <v-list-item-title class="add-section">
            Create Shop</v-list-item-title
          >
        </router-link>
      </v-list-item>
    </div>

    <div v-if="facilities.length">
      <v-list-group
        :value="slug == facility.id ? true : false"
        v-for="facility in facilities"
        :key="facility.id"
        class="facility-sub-menu">
        <!-- @click="shopOpened(facility.id)" -->
        <template v-slot:activator>
          <v-list-item-avatar>
            <img
              v-if="facility.logo"
              :src="facility.logo"
              class="avatar small" />
            <img
              v-else
              src="@/assets/images/facility-placeholder.jpg"
              class="avatar small" />
          </v-list-item-avatar>
          <v-list-item-content class="facility_details">
            <v-list-item-title>
              {{ facility.name }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <router-link :to="'/shop/' + facility.id + '/dashboard'">
          <v-list-item class="facility_dashboard">
            <v-list-item-title>Dashboard </v-list-item-title>
          </v-list-item>
        </router-link>
      
        <router-link
          :to="'/shop/' + facility.id + '/orders'"
          v-if="getPrivileges(facility.id, 'order_history')">
          <v-list-item class="facility_orders">
            <v-list-item-title>Orders </v-list-item-title>
          </v-list-item>
        </router-link>
        <router-link
          :to="'/shop/' + facility.id + '/credit-orders'"
          v-if="getPrivileges(facility.id, 'order_history') && toolSubscribed(facility.id, 'credit_order')">
          <v-list-item class="facility_orders">
            <v-list-item-title>Credit Orders </v-list-item-title>
          </v-list-item>
        </router-link>
        <router-link
          :to="'/shop/' + facility.id + '/reports'"
          v-if="getPrivileges(facility.id, 'reports')">
          <v-list-item class="facility_reports">
            <v-list-item-title>Sales Reports </v-list-item-title>
          </v-list-item>
        </router-link>
        <router-link :to="'/shop/' + facility.id + '/analytics'"  v-if="getPrivileges(facility.id, 'reports')">
          <v-list-item class="facility_analytics">
            <v-list-item-title>Analytics </v-list-item-title>
          </v-list-item>
        </router-link>
        

        <router-link
          v-if="
            getPrivileges(facility.id, 'expense_management') &&
            toolSubscribed(facility.id, 'expense_management')
          "
          :to="'/shop/' + facility.id + '/expense-management'">
          <v-list-item class="facility_reports">
            <v-list-item-title>Expense Management </v-list-item-title>
          </v-list-item>
        </router-link>
        <span v-if="getPrivileges(facility.id, 'referral_management') && toolSubscribed(facility.id, 'referral_management')">
          <v-list-group
          sub-group
          class="facility-sub-multi-menu"
        >
        <template v-slot:activator>
          <router-link
          :to="'/shop/' + facility.id + '/referral-management'"
        
          >
          <v-list-item class="facility_warehouse sub-title">
            <v-list-item-title>Referral Management</v-list-item-title>
            
          </v-list-item>
        </router-link>
        
          </template>
          <router-link
          :to="'/shop/' + facility.id + '/referral/transaction'"
          >
          <v-list-item class="facility_warehouse">
            <v-list-item-title>Referral Transaction</v-list-item-title>
          </v-list-item>
        </router-link>
        
        </v-list-group>
        </span>
       
        <router-link :to="'/shop/' + facility.id + '/sources'">
          <v-list-item class="facility_sources">
            <v-list-item-title>Sources</v-list-item-title>
          </v-list-item>
        </router-link>
        <span v-if="toolSubscribed(facility.id, 'retail_shops') && getPrivileges(facility.id, 'menu_management')">
          <v-list-group
          sub-group
          class="facility-sub-multi-menu"
        >
        <template v-slot:activator>
          <router-link
          :to="'/shop/' + facility.id + '/catalogue'"
         >
          <v-list-item class="facility_catalogue sub-title">
            <v-list-item-title>Catalogue</v-list-item-title>
          </v-list-item>
        </router-link>
        
          </template>
          <router-link
          :to="'/shop/' + facility.id + '/catalogue/out-going'"
          >
          <v-list-item class="facility_warehouse">
            <v-list-item-title>Outbound Transfers</v-list-item-title>
          </v-list-item>
        </router-link>
        <router-link
          :to="'/shop/' + facility.id + '/catalogue/coming-in'"
          >
          <v-list-item class="facility_warehouse">
            <v-list-item-title>Inbound Transfers</v-list-item-title>
          </v-list-item>
        </router-link>
        <router-link
          :to="'/shop/' + facility.id + '/inventory-reports'"
          v-if="
            getPrivileges(facility.id, 'reports') &&
            toolSubscribed(facility.id, 'retail_shops')
          ">
          <v-list-item class="facility_reports">
            <v-list-item-title
              >Inventory Reports 
            </v-list-item-title>
          </v-list-item>
        </router-link>
        </v-list-group>
        </span>
        <span v-else>
          <router-link
          :to="'/shop/' + facility.id + '/catalogue'"
          v-if="getPrivileges(facility.id, 'menu_management')">
          <v-list-item class="facility_catalogue">
            <v-list-item-title>Catalogue </v-list-item-title>
          </v-list-item>
        </router-link>
        </span>
        <span v-if="toolSubscribed(facility.id, 'unit_recipe')">

        <span v-if="toolSubscribed(facility.id, 'retail_shops')">
          <v-list-group
          sub-group
          class="facility-sub-multi-menu"
        >
        <template v-slot:activator>
          <router-link
          :to="'/shop/' + facility.id + '/raw-materials-management'"
          v-if="getPrivileges(facility.id, 'material_management')"
          >
          <v-list-item class="facility_warehouse sub-title">
            <v-list-item-title>Materials </v-list-item-title>
            
          </v-list-item>
        </router-link>
        
          </template>
          <router-link
          :to="'/shop/' + facility.id + '/raw-materials/out-going'"
          v-if="getPrivileges(facility.id, 'material_management')"
          >
          <v-list-item class="facility_warehouse">
            <v-list-item-title>Outbound Transfers</v-list-item-title>
          </v-list-item>
        </router-link>
        <router-link
          :to="'/shop/' + facility.id + '/raw-materials/coming-in'"
          v-if="getPrivileges(facility.id, 'material_management')"
          >
          <v-list-item class="facility_warehouse">
            <v-list-item-title>Inbound Transfers</v-list-item-title>
          </v-list-item>
        </router-link>
        <router-link
          :to="'/shop/' + facility.id + '/raw-materials/inventory-reports'"
          v-if="
            getPrivileges(facility.id, 'reports') 
          ">
          <v-list-item class="facility_reports">
            <v-list-item-title
              >Inventory Reports 
            </v-list-item-title>
          </v-list-item>
        </router-link>
        </v-list-group>
        </span>
        <span v-else>
          <router-link
          :to="'/shop/' + facility.id + '/raw-materials-management'"
          
          ><!-- v-if="getPrivileges(facility.id, 'menu_management')" -->
          <v-list-item class="facility_warehouse sub-title">
            <v-list-item-title>Materials </v-list-item-title>
            
          </v-list-item>
        </router-link>
        
        </span>
      </span>
        
        
       
        <router-link
          :to="'/shop/' + facility.id + '/warehouse-management'"
          v-if="
            toolSubscribed(facility.id, 'unit_recipe') &&
            (getPrivileges(facility.id, 'create_or_show_warehouse_request') ||
              getPrivileges(facility.id, 'approve_or_reject_warehouse_request'))
          ">
          <v-list-item class="facility_warehouse">
            <v-list-item-title>Stores Requisition</v-list-item-title>
          </v-list-item>
        </router-link>
        <router-link v-if="isOwner" :to="'/shop/' + facility.id + '/team'">
          <v-list-item class="facility_team">
            <v-list-item-title>Team </v-list-item-title>
          </v-list-item>
        </router-link>
        <router-link
          v-if="toolSubscribed(facility.id, 'coupons')"
          :to="'/shop/' + facility.id + '/coupon'">
          <v-list-item class="facility_coupons">
            <v-list-item-title>Coupons </v-list-item-title>
          </v-list-item>
        </router-link>
        <router-link
          v-if="isOwner && toolSubscribed(facility.id, 'shop')"
          :to="'/shop/' + facility.id + '/tool-kit'">
          <v-list-item class="facility_tools">
            <v-list-item-title>Tool Kit </v-list-item-title>
          </v-list-item>
        </router-link>
        <router-link
          :to="'/shop/' + facility.id + '/settings'"
          v-if="getPrivileges(facility.id, 'shop_settings')">
          <v-list-item class="facility_settings">
            <v-list-item-title>Shop Settings </v-list-item-title>
          </v-list-item>
        </router-link>
        <router-link v-if="isOwner" :to="'/shop/' + facility.id + '/edit'">
          <v-list-item>
            <v-list-item-title>Edit Shop </v-list-item-title>
          </v-list-item>
        </router-link>
        <router-link
          :to="'/shop/' + facility.id + '/vendors'"
          v-if="toolSubscribed(facility.id, 'retail_shops')">
          <v-list-item>
            <v-list-item-title>Vendors</v-list-item-title>
          </v-list-item>
        </router-link>
        <!--
        <router-link
          :to="'/shop/' + facility.id + '/knowledge-base'"
          v-if="toolSubscribed(facility.id, 'shop')">
          <v-list-item>
            <v-list-item-title>Knowledge Base</v-list-item-title>
          </v-list-item>
        </router-link>
        -->
      </v-list-group>
    </div>
    <div v-if="isOwner">
      <v-list-item class="business_settings">
        <router-link :to="{ name: 'merchant-edit' }">
          <v-list-item-title>Business Settings</v-list-item-title>
        </router-link>
      </v-list-item>
    </div>
    <div v-if="isOwner">
      <router-link :to="{ name: 'merchant-subscriptiondetails' }">
        <v-list-item class="subscription_details">
          <v-list-item-title>Subscription Details</v-list-item-title>
        </v-list-item>
      </router-link>
    </div>
    <div v-if="isOwner">
      <router-link :to="{ name: 'business-analytics' }">
        <v-list-item class="business_analytics">
          <v-list-item-title>Business Analytics</v-list-item-title>
        </v-list-item>
      </router-link>
    </div>
    <div v-if="isOwner">
      <router-link :to="{ name: 'merchant-account-info' }">
        <v-list-item class="account_info">
          <v-list-item-title>Account Information</v-list-item-title>
        </v-list-item>
      </router-link>
    </div>
  </v-list>
</template>
<script>
export default {
  name: "Sidebar",

  data() {
    return {
      privileges: {},
    };
  },
  computed: {
    facilities() {
      return this.getFacilities();
    },

    slug() {
      return this.$route.params.id ? this.$route.params.id : null;
    },
  },
  mounted() {},
  methods: {
    shopOpened(fid) {},
  },
};
</script>

<style>
.sup {
  vertical-align: super;
  font-size: 8px;
  color: #f422c0;
}
</style>
